<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_17_204)">
      <path
        d="M16.6666 2.99998H15.8333V2.16665C15.8333 1.70831 15.4583 1.33331 15 1.33331C14.5416 1.33331 14.1666 1.70831 14.1666 2.16665V2.99998H5.83329V2.16665C5.83329 1.70831 5.45829 1.33331 4.99996 1.33331C4.54163 1.33331 4.16663 1.70831 4.16663 2.16665V2.99998H3.33329C2.41663 2.99998 1.66663 3.74998 1.66663 4.66665V18C1.66663 18.9166 2.41663 19.6666 3.33329 19.6666H16.6666C17.5833 19.6666 18.3333 18.9166 18.3333 18V4.66665C18.3333 3.74998 17.5833 2.99998 16.6666 2.99998ZM15.8333 18H4.16663C3.70829 18 3.33329 17.625 3.33329 17.1666V7.16665H16.6666V17.1666C16.6666 17.625 16.2916 18 15.8333 18Z"
        fill="#3C464D"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_204">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
