<script lang="ts" setup>
defineProps<{
  modelValue: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()
</script>

<template>
  <v-text-field
    :model-value="modelValue"
    type="date"
    variant="outlined"
    single-line
    density="compact"
    hide-details
    base-color="secondary"
    bg-color="white"
    color="secondary"
    placeholder="Choose a date"
    @update:modelValue="emit('update:modelValue', $event)"
  ></v-text-field>

  <!-- <v-date-picker elevation="24"></v-date-picker> -->
</template>
