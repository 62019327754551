<script lang="ts" setup>
import { getCategories } from '@/services/CategoryService'

defineProps<{
  modelValue: string[]
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

const categories = getCategories()
</script>

<template>
  <v-select
    :model-value="modelValue"
    single-line
    :items="categories"
    item-title="name"
    item-value="name"
    variant="outlined"
    multiple
    chips
    closable-chips
    hide-details
    density="compact"
    placeholder="Search by category"
    @update:modelValue="emit('update:modelValue', $event)"
  ></v-select>
</template>
