<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_17_212)">
      <path
        d="M9.29165 3.33335L6.19165 8.40002C5.84998 8.95002 6.24998 9.66668 6.89998 9.66668H13.0916C13.7416 9.66668 14.1416 8.95002 13.8 8.40002L10.7083 3.33335C10.3833 2.80002 9.61664 2.80002 9.29165 3.33335Z"
        fill="#3C464D"
      />
      <path
        d="M14.5833 18.8333C16.6544 18.8333 18.3333 17.1544 18.3333 15.0833C18.3333 13.0122 16.6544 11.3333 14.5833 11.3333C12.5122 11.3333 10.8333 13.0122 10.8333 15.0833C10.8333 17.1544 12.5122 18.8333 14.5833 18.8333Z"
        fill="#3C464D"
      />
      <path
        d="M3.33333 18.4167H8.33333C8.79167 18.4167 9.16667 18.0417 9.16667 17.5833V12.5833C9.16667 12.125 8.79167 11.75 8.33333 11.75H3.33333C2.875 11.75 2.5 12.125 2.5 12.5833V17.5833C2.5 18.0417 2.875 18.4167 3.33333 18.4167Z"
        fill="#3C464D"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_212">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
