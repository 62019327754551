<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_17_196)">
      <path
        d="M9.99999 2.16669C6.77499 2.16669 4.16666 4.77502 4.16666 8.00002C4.16666 11.475 7.84999 16.2667 9.36666 18.0917C9.69999 18.4917 10.3083 18.4917 10.6417 18.0917C12.15 16.2667 15.8333 11.475 15.8333 8.00002C15.8333 4.77502 13.225 2.16669 9.99999 2.16669ZM9.99999 10.0834C8.84999 10.0834 7.91666 9.15002 7.91666 8.00002C7.91666 6.85002 8.84999 5.91669 9.99999 5.91669C11.15 5.91669 12.0833 6.85002 12.0833 8.00002C12.0833 9.15002 11.15 10.0834 9.99999 10.0834Z"
        fill="#3C464D"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_196">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
